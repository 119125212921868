export default {
    methods: {
        destroy(uid, callBackHandler) {
            axios({
                method: 'delete',
                url: `/files/${uid}/delete`,
            }).then(function (response) {
                callBackHandler(response.data);
            }.bind(this)).catch(function (error) {
                console.log(error)
                if (error.response) {
                    callBackHandler(error.response);
                }
            })
        }
    }
}