<template>
  <div>
    <div>
      <nav class="header-navbar navbar-flipped navbar-expand-md navbar navbar-with-menu fixed-top navbar-dark bg-primary navbar-shadow navbar-brand-center">
        <div class="navbar-wrapper">
            <div class="navbar-header" style="padding-right: 7px;padding-left: 7px;">
                <ul class="nav navbar-nav flex-row" style="text-align: center;">
                    <li class="nav-item mobile-menu d-md-none mr-auto">
                      <a class="nav-link nav-menu-main menu-toggle hidden-xs" href="#" style="margin-top: 3px;"><i class="feather icon-menu font-large-1"></i></a>
                    </li>
                    <li class="nav-item"><a class="navbar-brand">
                      <img class="brand-logo" src="assets/images/custom-logo.png" style="text-align: center;"></a>
                    </li>
                    <li class="nav-item d-none">
                      <a class="nav-link open-navbar-container" data-toggle="collapse" data-target="#navbar-mobile"><i class="fa fa-ellipsis-v"></i>
                    </a>
                    </li>
                </ul>
            </div>
            <div class="navbar-container content" style="width: 100%;">
                <div class="collapse navbar-collapse" id="navbar-mobile">
                    <ul class="nav navbar-nav mr-auto float-left">
                        <li class="nav-item d-none d-md-block"><a class="nav-link nav-menu-main menu-toggle hidden-xs d-md-none" href="#" style="margin-top: 3px;"><i class="feather icon-menu"></i></a></li>
                    </ul>
                    <ul class="nav navbar-nav float-right">
                        <li class="dropdown dropdown-user nav-item">
                            <a class="dropdown-toggle nav-link dropdown-user-link" href="#" data-toggle="dropdown">
                                <!-- <div class="avatar avatar-online">
                                    <img :src="user.avatar" alt="avatar">
                                    <i></i>
                                </div> -->
                                <span class="user-name">{{user.firstname}} {{user.lastname}}</span>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" :href="external_dashboard_path" target="_blank"><i class="feather icon-layout"></i> My Dashboard</a>
                                <div class="dropdown-divider"></div>
                                <a @click="logout" class="dropdown-item" style="cursor: pointer"><i class="feather icon-power"></i> Logout</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
    
    <div class="main-menu menu-fixed menu-light menu-accordion menu-shadow menu-border right">
        <div class="main-menu-content menu-container">
            <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
                <li>
                    <a>
                        <div class="avatar avatar-online">
                            <img :src="user.avatar" alt="avatar">
                        </div>
                        <span class="user-name ml-1" style="font-weight: 700; font-size: 0.8rem;">{{user.firstname}} {{user.lastname}}</span>
                    </a>
                    <div class="dropdown-divider"></div>
                    <a @click="closeMenu" :href="external_dashboard_path" target="_blank"><i class="feather icon-layout"></i><span class="menu-title" data-i18n="Dashboard">My Dashboard</span>
                        <!-- <span class="badge badge badge-primary badge-pill float-right mr-2">3</span> -->
                    </a>
                    <a @click="logout" style="cursor: pointer;"><i class="feather icon-power"></i><span class="menu-title" data-i18n="Dashboard">Logout</span>
                        <!-- <span class="badge badge badge-primary badge-pill float-right mr-2">3</span> -->
                    </a>
               </li>
            </ul>
            <div v-show="app_version" class="version-div text-right">
              <hr>
              <div style="font-size: 0.9rem;">
                <b>v{{app_version}}</b>
              </div>
            </div>
        </div>
    </div>

       <div class="app-content content" style="overflow: hidden;">
           <div class="sidebar-left">
               <div class="sidebar">
                 <Profile :user="user"/>
                   <div class="chat-sidebar card">
                       <span class="chat-sidebar-close">
                           <i style="display: none;" class="feather icon-x"></i>
                       </span>
                       <!--  <div class="chat-sidebar-search">
                           <div class="d-flex align-items-center">
                              <div class="chat-sidebar-profile-toggle">
                                   <div class="avatar">
                                       <img :src="user.avatar" height="36" width="36">
                                   </div>
                               </div>
                               <fieldset class="form-group position-relative has-icon-left mx-75 mb-0">
                                   <input type="text" class="form-control round" id="chat-search" placeholder="Search">
                                   <div class="form-control-position">
                                       <i class="feather icon-search text-dark"></i>
                                   </div>
                               </fieldset>
                           </div>
                       </div> -->
                       <div class="chat-sidebar-list-wrapper">
                           <h6 class="px-2 pb-25 mb-0" style="font-size: 1.1rem;">CHANNELS
                             <!-- <i class="feather icon-plus float-right cursor-pointer"></i> -->
                           </h6>
                           <ul class="chat-sidebar-list" id="channels">
                             <template v-if="public_channels.length > 0">
                               <li @click="setActiveChannel(channel)" v-for="(channel, index) in public_channels" 
                               :id="'public-channel-id-' + channel.id" :data-public-channel-id="channel.id">
                               <div class="row" style="margin-right: 0;">
                                <div class="col-1 col-sm-2 pr-0">
                                  <div class="avatar avatar-busy m-0 mr-50">
                                    <img :src="getChannelLogo(channel.logo)" height="36" width="36" style="border-radius: 0px;">
                                  </div>
                                </div>
                                <div class="col-5 col-sm-7" style="padding-top: 8px;">
                                  <div class="chat-sidebar-name">
                                    <h6 style="font-weight: 500; font-size: 0.9em;">
                                      <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{channel.name}}</div>
                                      
                                    </h6>
                                  </div>
                                </div>
                                <div class="col-1 col-sm-1">
                                  <div v-if="channel.UserChannel.user_id == user.id" class="badge">{{channel.UserChannel.unread <= 999 ? channel.UserChannel.unread : "999+"}}</div>
                                </div>
                              </div>
                               </li>
                             </template>
                             <template v-else>
                               <div class="px-2 pb-25 mb-0 mt-1" style="font-size: 0.9rem;">No active channels</div>
                             </template>
                           </ul>
                           <h6 class="px-2 pt-2 pb-25 mb-0" style="font-size: 1.1rem;">DIRECT MESSAGES</h6>
                           <ul class="chat-sidebar-list" id="chats">
                             <template v-if="private_channels.length > 0">
                               <li @click="setActiveChannel(channel)" v-for="(channel, index) in private_channels" 
                               :id="'private-channel-id-' + channel.id" :data-private-channel-id="channel.id"
                               style="padding-top: 6px; padding-bottom: 6px;">
                                 <div class="align-items-center">
                                   <div class="row" style="margin-right: 0;">
                                     <div class="col-1 col-sm-2">
                                       <div class="avatar avatar-busy m-0 mr-50">
                                         <img :src="getPrivateChannelAvatar(channel)" height="36" width="36">
                                        <!--  <status-icon :connected="user.connected" /> -->
                                       </div>
                                     </div>
                                     <div class="col-5 col-sm-8" style="padding-top: 8px;">
                                       <div class="chat-sidebar-name">
                                         <h6 style="font-weight: 500; font-size: 0.9em;">{{channel.name}}
                                        </h6>
                                      </div>
                                    </div>
                                    <div class="col-1 col-sm-1">
                                       <div v-if="channel.UserChannel.user_id == user.id" class="badge">{{channel.UserChannel.unread <= 999 ? channel.UserChannel.unread : "999+"}}</div>
                                    </div>
                                   </div>
                                     <!-- <div v-if="user.hasNewMessages" class="new-messages">!</div> -->
                                 </div>
                               </li>
                             </template>
                             <template v-else>
                               <div class="px-2 pb-25 mb-0 mt-1" style="font-size: 0.9rem;">No direct messages</div>
                             </template>
                           </ul>
                       </div>
                   </div>
                   <!-- app chat sidebar ends -->
               </div>
           </div>
           <div class="content-right">
               <div class="content-overlay"></div>
               <div class="content-wrapper">
                   <div class="content-header row">
                   </div>
                   <div class="content-body">
                       <!-- app chat overlay -->
                       <div class="chat-overlay"></div>
                       <!-- app chat window start -->
                       <section class="chat-window-wrapper">
                         <!-- <div v-show="selectedChannel" class="chat-area d-none"> -->
                         <div v-show="selectedChannel" class="chat-area">
                             <div class="chat-header" style="background-color: #e7e6e7;">
                              <header class="d-flex justify-content-between align-items-left px-1 py-75" style="display: inline-flex !important;">
                               <a class="chat-sidebar-toggle d-block d-lg-none mr-1" style="margin-top: 1%; text-decoration: none; cursor: pointer;" href="#">
                                   <i @click="resetUnreadMessagesCall(selectedChannel.id, user.id)" class="feather icon-arrow-left font-large-1 cursor-pointer" style="color: #68686a; font-size: 1.2rem; padding: 7px"></i>
                               </a>
                               <div @click="getChannelContacts" class="chat-profile-toggle" style="cursor: pointer;">
                                 <div v-show="selectedChannel.type == 'private'" v-for="(participant, index) in selectedChannel.participants" style="flex: 0 0 auto">
                                   <div class="d-flex align-items-center">
                                     <div class="row">
                                       <div class="col-12" style="display: inline;">
                                         <div class="row">
                                           <div class="col-3">
                                             <img :src="getAvatar(participant.avatar)" height="36" width="36" style="border-radius: 0;" />
                                           </div>
                                           <div class="col-9">
                                             <b style="font-size: 0.9rem;">{{participant.firstname}} {{participant.lastname}}</b>
                                             <div style="font-size: 0.8rem; font-weight: 600;">{{participant.organization}}</div>
                                           </div>
                                           </div>
                                         </div>
                                       </div>
                                   </div>
                                 </div>
                                  <div v-show="selectedChannel.type == 'public'" class="row">
                                    <div class="col-2">
                                      <img :src="getChannelLogo(selectedChannel.logo)" height="36" width="36" style="border-radius: 0;" />
                                    </div>
                                    <div class="col-10">
                                      <b style="font-size: 0.9rem;">{{selectedChannel.name}}</b>
                                      <div style="font-size: 0.8rem; font-weight: 600;"><span class="text-content"></span> to send direct messages</div>
                                    </div>
                                  </div>
                               </div>
                             </header>
                             </div>
   
                             <!-- chat card start -->
                             <div class="card chat-wrapper shadow-none mb-0">
                               <div class="card-content">
                                   <div class="card-body chat-container" id="chat-container">
                                       <div v-if="messages.length > 0" class="chat-content" style="overflow-x: hidden; overflow-y: auto;">
                                           <!-- <div class="badge badge-pill badge-light-secondary my-1">Yesterday</div> -->
                                           <!-- <infinite-loading direction="top" @infinite="getChannelMessgesCall"></infinite-loading> -->
                                           <div v-for="(message, index) in messages" :class="{'chat' :  message.user_id == user.id, 'chat chat-left' : message.user_id != user.id}" :key="index">
                                             <!-- <div v-if="message.user"> -->
                                               <div v-if="message.user" class="chat-avatar">
                                                   <a class="avatar m-0" :data-tooltip="message.user.firstname +' '+ message.user.lastname + ' (' + message.user.organization + ')'"
                                                   :data-tooltip-location="message.user_id == user.id ? 'left' : 'right'">
                                                       <img :src="getAvatar(message.user.avatar)" height="36" width="36" />
                                                   </a>
                                                   <div v-if="message.user_id != user.id" style="font-size: 0.8rem;">
                                                     <div style="font-weight: 600;">{{message.user.firstname}}</div>
                                                     <div style="font-weight: 400; font-size: 0.7rem;">{{message.user.organization}}</div>
                                                   </div>
                                                   <span v-if="message.user_id == user.id" class="dropdown">
                                                       <i class="feather icon-more-vertical font-medium-4 ml-25 cursor-pointer dropdown-toggle nav-hide-arrow cursor-pointer" style="color: #d0d0d0" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="menu">
                                                       </i>
                                                       <span class="dropdown-menu" :class="{'dropdown-menu-right' :  message.user_id == user.id, 'dropdown-menu-left' : message.user_id != user.id}" aria-labelledby="dropdownMenuButton">
                                                           <!-- <a class="dropdown-item" href="JavaScript:void(0);"><i class="feather icon-tag mr-25"></i> Pin to top</a> -->
                                                           <a class="dropdown-item" @click="removeMessage(message.id)" href="#"><i class="feather icon-trash-2 mr-25"></i>Delete</a>
                                                           <!-- <a class="dropdown-item" href="JavaScript:void(0);"><i class="feather icon-x-circle mr-25"></i> Block</a> -->
                                                       </span>
                                                   </span>
                                               </div>
                                               <div v-if="message.user" class="chat-body" :class="{'_last' : messages[messages.length - 1].id == message.id}">
                                                   <div class="chat-message text-left" :class="{'hide-msg' : !message.content}">
                                                     <div :class="{'files-count' : (!message.content)}" v-html="getMessageContent(message)" v-linkify:options="getOptions(message.user_id)"></div>
                                                       <!-- <p :class="{'files-count' : (!message.content)}">{{message.content ? message.content : (message.files.length == 1 ?  message.files[0].original_filename :  message.files.length + ' files')}}</p> -->
                                                       <div v-if="message.content && message.files.length == 0" style="text-align: right; font-size: 0.78rem; padding: 2px;" class="chat-time" :class="{'date-text-left' : message.user_id != user.id, 'date-text-right' : message.user_id == user.id}">{{moment(message.created_at).format("ddd, MMM D")}}</div>
                                                   </div>
                                               <!-- </div> -->
                                               <Files v-if="message.user && message.files.length > 0" :files="message.files" :messageUserId="message.user_id" 
                                                 @fileRemoved="fileRemovedHandler" :messageId="message.id" :user="user" :createdAt="message.created_at"
                                                 :hasText="message.content != '' ? true : false"/>
                                             </div>
                                           </div>
                                       </div>
                                       <div v-else class="center-spinner">
                                           <span class="chat-sidebar-toggle chat-start-icon font-small-9 p-3 mb-1"></span>
                                           <div class="text-center">
                                             <div v-if="busy" class="chaser"></div>
                                             <h5 v-else class="py-20 text-bold-500 mt-2">
                                              {{load_message}}
                                             </h5>
                                           </div>
                                       </div>
                                   </div>
                                  </div>
                                  <div class="card-footer chat-footer px-2 py-1 pb-0">
                                   <!-- <div class="ml-2" v-if="filenames.length > 0" style="padding: 2px;">
                                       <span v-for="(name, index) in filenames">{{name}} |</span>
                                      </div> -->
                                   <form class="d-flex align-items-center" @submit.prevent="onChatMessage" method="post" autocomplete="off">
                                     <div class="image-upload">
                                           <label for="files_" style="margin-top: 20%;">
                                            <i class="feather icon-paperclip cursor-pointer" style="font-size: 1.4rem; margin-right: 5px;"></i>
                                          </label>
                                          <input id="files_" @change="onChange" name="imagesArray" type="file" multiple/>
                                        </div>
                                        <span @click="showFilesPreviewModal" v-if="imagesArray.length > 0" class="files-qty-label"><b>{{imagesArray.length + ' files'}}</b></span>
                                       <input v-model="content" type="text" class="form-control chat-message-send mx-1"
                                         placeholder="Type your message..." >
                                       <button type="submit" class="btn btn-primary glow send d-lg-flex">
                                         <i :class="{'fa fa-circle-o-notch fa-spin' : loading , 'feather icon-play' : !loading}" style="margin-top: 3%;"></i>
                                           <span class="d-none d-lg-block mx-50" style="font-size: 0.8rem;">SEND</span></button>
                                   </form>
                               </div>
                           </div>
                             <!-- chat card ends -->
                         </div>
                         <div v-show="!selectedChannel" class="chat-start">
                             <span class="feather icon-message-square chat-sidebar-toggle chat-start-icon font-large-2 p-3 mb-1"></span>
                             <h4 class="d-none d-lg-block py-50 text-bold-500">Select a channel to start a chat!</h4>
                             <button class="btn btn-light-primary chat-start-text chat-sidebar-toggle d-block d-lg-none py-50 px-1" style="color: black; font-size: 0.9rem;">Start
                                 Conversation!</button>
                         </div>
                       </section>
                       <!-- app chat window ends -->
                       <!-- app chat profile right sidebar start -->
                       <section class="chat-profile">
                         <header class="chat-profile-header text-center border-bottom">
                             <span class="chat-profile-close">
                                 <i class="feather icon-x"></i>
                             </span>
                             <div class="my-2">
                               <template v-if="selectedChannel.type == 'public'">
                                 <img :src="getChannelLogo(selectedChannel.logo)" class="mb-1" height="90" width="100">
                                 <h5 class="app-chat-user-name mb-0"><b>{{selectedChannel.name}}</b></h5>
                               </template>
                               <template v-else>
                                 <img :src="getStatisAsset('assets/images/group.svg')" class="round mb-1" height="150" width="150">
                                 <h5 class="app-chat-user-name mb-0">{{selectedChannel.participants ? (selectedChannel.participants.length + 1) + ' members' : 'Private channel'}} </h5>
                               </template>
                                 <!-- <span>Devloper</span> -->
                             </div>
                         </header>
                         <div class="chat-profile-content p-2">
                           <!-- <template v-if="selectedChannel.type == 'public'">
                             <p class="text-center">{{this.selectedChannel.description}}</p>
                             <hr/>
                           </template> -->
                           <h6 class="px-2 pb-25 mb-1" style="font-size: 0.8rem;">CHANNEL MEMBERS</h6>
                           <ul class="chat-sidebar-list" style="list-style: none; padding-left: 0">
                             <template v-if="channel_contacts.length > 0">
                               <li  v-for="(contact, index) in channel_contacts" :key="index" style="padding-top: 1%; cursor: pointer;">
                                   <div @click="addPrivateChannel(contact)" class="d-flex align-items-center" style="border-bottom: 1px #eaeaea solid; margin-bottom: 5px; padding-bottom: 3px;">
                                       <div class="avatar avatar-away m-0 mr-50"><img :src="getAvatar(contact.avatar)" height="36" width="36">
                                           <!-- <i></i> -->
                                       </div>
                                       <div class="chat-sidebar-name pl-1">
                                           <h6 class="mb-0">{{contact.firstname}} {{contact.lastname}}</h6>
                                           <span class="text-muted" style="font-size: 0.8rem; font-weight: 600;">{{contact.state ? contact.organization + '-' + contact.state : contact.organization}}</span>
                                       </div>
                                   </div>
                               </li>
                             </template>
                             <template v-else>
                               <li style="padding-top: 2%; padding-bottom: 2%;">
                                 No active members
                               </li>
                             </template>
                           </ul>
                         </div>
                     </section>
                       <!-- app chat profile right sidebar ends -->
                   </div>
               </div>
           </div>
           <div v-if="show_refresh_button" @click="moveScrollToBottom" style="text-align: center; position: fixed; width: 100%; top: 20%; background-color: transparent">
             <button class="float-btn-refresh">New message available</button>
           </div>
       </div>
   
       <div class="sidenav-overlay"></div>
       <div class="drag-target"></div>

       <FilesPreview v-if="imagesArray.length > 0" :files="imagesArray" @filePreviewRemoved="filePreviewRemovedHandler" />
   
      <Footer class="d-none d-lg-block"/>
      
    </div>
    <audio autoplay id="audio_"> <source src="sounds/juntos-607.mp3"></audio>
    <!-- <button v-if="go_down" @click="moveScrollToBottom" type="button" class="btn btn-float btn-outline-secondary btn-round scroll-down-btn">
      <i class="fa fa-chevron-down" style="margin-left: -5px;"></i>
    </button> -->
  </div>
</template>


<script>
// @ is an alias to /src
import socket from "./../socket";
import moment from 'moment';
import Storage from '@/utils/storage.js';
import messageService from "@/services/messages.js";
import channelService from "@/services/channels.js";
import deviceService from "@/services/device.js";

import Profile from '@/components/Profile.vue'
import Files from '@/components/Files.vue'
import FilesPreview from '@/components/FilesPreview.vue'
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import WebSplash from '@/components/WebSplash.vue'
import linkify from 'vue-linkify'
import {eventBus} from '@/eventBus';

export default {
    name: 'Chat',
    directives: {
      linkify
    },
    components: {
        Files,
        FilesPreview,
        Navbar,
        Footer,
        Profile,
        WebSplash
    },
    mixins: [messageService, channelService, deviceService],
    props: {
      current_user: Object
    },
    data(){
        return {
          user: {id: ""},
          selectedChannel: '',
          private_channels: [],
          public_channels: [],
          channel_contacts: [],
          all_channels: [],
          new_contact: '',
          new_channel: '',
          moment: moment,
          messages: [],
          content: '',
          imagesArray: [],
          filenames: [],

          chat_content: '',
          loading: false,
          busy: 0,
          pagination: {
            per_page: 10,
            more: true,
            last_id: 0
          },
          go_down: false,
          show_refresh_button: false,
          chat_user_user: '',
          calls: 0,
          scripts_loaded: false,
          external_dashboard_path: '',
          _container: undefined,
          load_message: '',
          socket_connected: false,
          msg_queue: [],
          app_version: ''
        }
    },
    watch: {
      socket_connected(val){
        if(val == false){
          this.openConnection(this.user);
        }
      },
      "user.id"(val){
        if(!val > 0){
          this.user = Storage.get("user");
        }
      },
      busy(val){
        if(val == 0){
          if(this.msg_queue.length > 0){
            let incoming = this.msg_queue.reverse().concat(this.messages);
            this.msg_queue = [];
            this.messages = Array.from(new Set(incoming.map(a => a.id)))
            .map(id => {
              return incoming.find(a => a.id == id)
            });
            this.messages.sort(function (a, b) {
              if (Date(a.created_at) > Date(b.created_at)) return 1;
              if (Date(a.created_at) < Date(b.created_at)) return -1;
              return 0;
            });
          }
        }
      }
    },
    methods : {
      getChannelMessgesCall(){
        if(this.selectedChannel.id > 0){
          if(this.pagination.more && this.busy == 0){
            this.calls++;
            /* if(this.calls > 1){
              this.pagination.per_page = 5
            } */
            let query = {"order_by" : {"created_at" : "desc"}, "take": this.pagination.per_page};
            query.where = [];
            query.where.push(['channel_id', this.selectedChannel.id]);
            query.with = ['user', 'files', 'channel'];
            if(this.pagination.last_id != 0){
              query.where.push(['id', 'lt', this.pagination.last_id]);
            }
            this.searchMessages(query, this.channelMessgesCallback);
            this.busy = 1;
            
          }
        }
      },
      channelMessgesCallback(response){
        this.busy = 0;
        if(response.status == 1) {
          if(response.data.length > 0) {
            this.pagination.last_id = response.data[response.data.length - 1].id;

            let incoming = response.data.reverse().concat(this.messages);
            this.messages = Array.from(new Set(incoming.map(a => a.id)))
            .map(id => {
              return incoming.find(a => a.id == id)
            });
            this.messages.sort(function (a, b) {
              if (Date(a.created_at) > Date(b.created_at)) return 1;
              if (Date(a.created_at) < Date(b.created_at)) return -1;
              return 0;
            });

            if(this.pagination.more){
              if(this.calls == 1){
                this.moveScrollToBottom();
              }
              else {
                this.moveScrollToTopWithOffset();
              }
            } else {
              this.moveScrollToBottom();
            }

          } else {
            this.pagination.more = false;
            if(this.messages.length == 0){
              this.load_message = "No messages in this channel";
              this.moveScrollToBottom();
            }
          }

          this.$nextTick(() => {
            this._container.update();
          });
        }
      },
      getPrivateChannelAvatar(channel){
        if(channel.participants.length > 0){
          if(channel.participants.length == 1){
            if(channel.participants[0].avatar){
              return process.env.VUE_APP_SERVER + channel.participants[0].avatar
            } else {
              return this.getStatisAsset("assets/images/avatar-placeholder.png")
            }
          } else {
            return this.getStatisAsset("assets/images/group-placeholder.png")
          }
        } else {
          return this.getStatisAsset("assets/images/group-placeholder.png")
        }
      },
      getChannelLogo(logo){
          if(logo){
              return process.env.VUE_APP_SERVER + logo
          } else {
            return this.getStatisAsset("assets/images/message-icon.png")
          }
      },
      openConnection(user){
        let user_ = user;
        if(!user_.id > 0){
          user_ = Storage.get("user");
        }
        socket.auth = {
          user: {
            id: user_.id,
            username: user_.username,
            firstname: user_.firstname,
            lastname: user_.lastname,
            email: user_.email
          }
        };
        socket.connect();
      },
      setActiveChannel(channel){
        this.selectedChannel = channel;
        this.messages = [];
        this.calls = 0;
        this.busy = 0;
        this.pagination.more = true;
        this.pagination.per_page = 10;
        this.pagination.last_id = 0;
        this.resetUnreadMessagesCall(this.selectedChannel.id, this.user.id);
        this.getChannelMessgesCall();
        $(".chat-sidebar").removeClass("show");
		    $(".chat-overlay").removeClass("show");
        this.setItemActive(channel.id, channel.type);
        
      },
      resetUnreadMessagesCall(channel_id, user_id){
        this.loading = false;
        this.go_down = false;
        this.resetUnreadMessages(channel_id, user_id, this.resetUnreadMessagesCallback);
      },
      getChannelsCallback(response, created_channel_id, created_channel_type){
        if(response.status > 0){
          this.all_channels = response.data;
          if(this.all_channels.length > 0){
            this.private_channels = [];
            this.public_channels = [];
            this.all_channels.forEach(channel => {
              if(channel.type == 'public'){
                this.public_channels.push(channel);
              } else if(channel.type == 'private'){
                channel.name = this.buildPrivateChannelName(channel.participants)
                channel.display_name = this.buildPrivateChannelDisplayName(channel.participants)
                this.private_channels.push(channel);
              }
            });
            this.$nextTick(() => {
              if(created_channel_id > 0 && created_channel_type != ""){
                  this.setItemActive(created_channel_id, created_channel_type)
                  $("#"+created_channel_type+"-channel-id-" + created_channel_id).click();
                }
              });
            }
          } else {
            console.log(response.errors)
          }
        },
        resetUnreadMessagesCallback(response){
          this.pagination.last_id = 0;
          if(response.status > 0){
            if(response.data.id > 0){
              let found = false;
              if(this.public_channels.length > 0){
                this.public_channels.forEach(element => {
                  if(element.id == response.data.id){
                    element.UserChannel.unread = null;
                    found = true;
                  }
                });
              }
              if(!found){
                if(this.private_channels.length > 0){
                  this.private_channels.forEach(element => {
                    if(element.id == response.data.id){
                      element.UserChannel.unread = null;
                      found = true;
                    }
                  });
                }
              }
            }
          } else {
            console.log(response.errors)
          }
        },
        buildPrivateChannelName(participants){
          if(participants.length > 0){
            let str_name = ""
            let length = participants.length;
            if(length == 1){
              str_name = participants[0].firstname + ' ' + participants[0].lastname
            } else if(length == 2) {
              str_name += str_name +', '+ participants[1].firstname + ' ' + participants[1].lastname
            } else if(length == 3) {
              str_name +=str_name + ',  ' +participants[2].firstname +' '+participants[2].lastname 
            } else {
              str_name += str_name + ', (and ' + length + ' more)'
            }
            if(str_name.length >= 27){
              return str_name = str_name.substring(0,27) + '...';
            } else {
              return str_name;
            }
          } else {
            return 'Private channel'
          }
        },
        buildPrivateChannelDisplayName(participants){
          if(participants.length > 0){
            let length = participants.length;
            if(length == 1){
              return participants[0].firstname + ' ' + participants[0].lastname
            } else if(length == 2) {
              return participants[0].firstname + ', ' + participants[0].lastname + ', ' + participants[1].firstname + ', ' + participants[1].lastname
            } else if(length == 3) {
              return participants[0].firstname + ', ' + participants[0].lastname + ', ' + participants[1].firstname + ', ' + participants[1].lastname + ', ' + participants[2].firstname + ', ' + participants[2].lastname
            } else {
              return participants[0].firstname + ', ' + participants[0].lastname + ', ' + participants[1].firstname + ', ' + participants[1].lastname + ', ' + participants[2].firstname + ', ' + participants[2].lastname + ' and ' + length + ' more'
            }
          } else {
            return 'Private channel'
          }
        },
        openSearchContactModal(){
          $('#add-contact').modal('show')
        },
        openSearchChannelModal(){
          $('#add-channel').modal('show')
        },
        getChannelContacts(){
            this.getChannelUsers(this.selectedChannel.id, this.getChannelContactsCallback)
        },
        getChannelContactsCallback(response){
          if(response.status > 0){
            this.channel_contacts = response.data;
          }
        },
        onChatMessage(){
          var self = this;
          if(this.content || this.imagesArray.length > 0){
            let formData = new FormData
            formData.append('user_id', this.user.id)
            formData.append('channel_id', this.selectedChannel.id)
            formData.append('content', this.content)

            if(this.imagesArray.length > 0){
                for (const i of Object.keys(this.imagesArray)) {
                    formData.append('imagesArray', this.imagesArray[i])
                }
            }
            if(!this.loading){
              this.loading = true;
              this.sendMessage(formData, this.sendMessageCallback);
              setTimeout(function(){
                if(self.loading){
                  self.loading = false;
                }
              }, 10000);
            }
          }
        },
        onChange (event) {
          if(this.imagesArray.length > 0){
            this.imagesArray = helpers.mergeArrays(this.imagesArray, Array.from(event.target.files))
          } else {
            this.imagesArray = Array.from(event.target.files);

          }
      },
      sendMessageCallback(response){
        this.loading = false;
          if(response.status > 0){
              this.messages.push(response.data);
              if(!socket.connected){
                this.openConnection(this.user)
              }
              socket.emit("channel message", {
                content: {
                  channel: this.selectedChannel,
                  message: response.data
                },
                to: this.selectedChannel.identifier
              });
              this.moveScrollToBottom();
              this.content = "";
              this.imagesArray = [];
              this.filenames = []
          } else {
              console.log(response.errors[0])
          }
      },
      removeMessage(id){
          if(id > 0){
            this.deleteMessage(id, this.removeMessageCallback);
          }
      },
      removeMessageCallback(response){
          if(response.status > 0){
              if(this.messages.length > 0){
                  this.messages.forEach((message, index) => {
                      if(message.id == response.data.id){
                          this.messages.splice(index, 1)
                      }
                  });
              }
          }
      },
      getAvatar(_filename){
          return _filename ? process.env.VUE_APP_SERVER + _filename : this.getStatisAsset('assets/images/avatar-placeholder.png')
      },
      fileRemovedHandler(ev){
        if(this.messages.length > 0){
          this.messages.forEach((message, i) => {
            if(message.id == ev.message_id){
              if(message.files.length > 0){
                message.files.forEach((file, j) => {
                  if(file.id == ev.id){
                    message.files.splice(j, 1)
                    if(message.files.length == 0 && !message.content){
                      this.removeMessage(message.id)
                    }
                  }
                });
              }
            }
          });
        }
      },
      addPrivateChannel(contact){
      let data = {
        user_id: this.user.id,
        participants: [contact.id],
        type: 'private',
        status: 'active'
      }
      this.createPrivateChannel(data, contact.id, this.addPrivateChannelCallback)
      },
      addPrivateChannelCallback(response, contact_id){
        if(response.status == 1){
          this.getChannels(this.user.id, response.data.id, response.data.type, this.getChannelsCallback)
            $(".chat-user-profile").removeClass("show");
            $(".chat-profile").removeClass("show");
            if (!$(".chat-sidebar").hasClass("show")) {
              $(".chat-overlay").removeClass("show");
            }

            socket.emit("subscribe private channel", {
              content: {
                participants: [contact_id, this.user.id],
                channel: response.data
              }
            });
            
        } else {
          this.getChannels(this.user.id, response.data.data[0].id, response.data.data[0].type, this.getChannelsCallback)
            $(".chat-user-profile").removeClass("show");
            $(".chat-profile").removeClass("show");
            if (!$(".chat-sidebar").hasClass("show")) {
              $(".chat-overlay").removeClass("show");
            }
        }
     },
     setItemActive(id, type){

      $("ul#chats li").removeClass('active')
      $("ul#channels li").removeClass('active')

      if(type == "private"){
        $("#private-channel-id-" + id).addClass('active')
      }
      if(type == "public"){
        $("#public-channel-id-" + id).addClass('active')
      }
     },
     getOptions(user_id){
       if(user_id == this.user.id){
        return { className: 'link-template-own' }
       } else {
        return { className: 'link-template-contact' }
       }
     },
     showFilesPreviewModal(){
      $('#files-preview').modal('show')
     },
     filePreviewRemovedHandler(index){
      if(this.imagesArray.length > 0){
        this.imagesArray.splice(index, 1);
        if(this.imagesArray.length == 0){
          $('#files-preview').modal('hide')
        }
      } else {
        $('#files-preview').modal('hide')
      }
     },
     getMessageContent(message){
      if(message.content){
        return `<div><p>${message.content}</p></div>
                <div style="float:right; padding-top: 5px; font-size: 0.72rem;">${moment(message.created_at).format("h:mm a")}</div>`
      } else {
        if(message.files.length == 1){
          return message.files[0].original_filename
        } else {
          return message.files.length + ' files'
        }
      }
     },
     getStatisAsset(path){
      if(typeof cordova != "undefined"){
        return path;
      } else {
        return '/' + path
      }
     },
     moveScrollToBottom(){
      this.$nextTick(() => {
        $("#chat-container").scrollTop($("#chat-container")[0].scrollHeight);
      });
      this.show_refresh_button = false;
      this.go_down = false;
    },
    moveScrolToTop(){
      $("#chat-container").animate({
          scrollTop: 0
      }, 200)
    },
    moveScrollToTopWithOffset(){
      $("#chat-container").animate({
          scrollTop: 50
      }, 200)
     },
     playNotificationSound(){
      if(typeof cordova != "undefined"){
          var audio_file = this.getMediaURL("sounds/juntos-607.mp3");
          var media = new Media(audio_file,
          function () {},
          function (err) {
            console.log("playAudio():Audio Error: " , JSON.stringify(err));
            }
          );
          media.setVolume(0.5);
          media.play();
      } else {
        document.getElementById("audio_").play();
      }
     },
     getMediaURL(s) {
      if(device.platform === "Android") return "/android_asset/www/" + s;
          return s;
     },
     getScriptTag(src) {
       return {
          value : Boolean(document.querySelector('script[src="' + src + '"]')),
          element: document.querySelector('script[src="' + src + '"]')
       }
     },
     logout(){
        Storage.remove("token");
        Storage.remove("user");
        Storage.remove("reloaded");
        this.$router.push("/login");
        window.location.reload();
      },
      closeMenu(){
          $.app.menu.hide()
          $(".chat-sidebar-toggle").click();
      },
      getScriptTag(src) {
        return {
          value : Boolean(document.querySelector('script[src="' + src + '"]')),
          element: document.querySelector('script[src="' + src + '"]')
        }
      },
      registerUserDevice(user_id, token){
        this.registerDevice({
          'user_id' : user_id,
          'registration_token' : token,
          'device_uuid' : Storage.get('device_uuid') ? Storage.get('device_uuid') : null, 
          'model' : Storage.get('device_model') ? Storage.get('device_model') : null, 
          'platform' : Storage.get('device_platform') ? Storage.get('device_platform') : null, 
          'serial' : (Storage.get('device_is_virtual') && Storage.get('device_is_virtual') != "undefined") ? Storage.get('device_serial') : null, 
          'is_virtual' : (Storage.get('device_is_virtual') && Storage.get('device_is_virtual') != "undefined") ? Storage.get('device_is_virtual') : false, 
          'status' : Storage.get('device_status') ? Storage.get('device_status') : null
        },this.registerDeviceCallback)
      },
      registerDeviceCallback(response){
        if(response.status > 0){
          console.log('Success registration', response)
        } else {
          console.log('Error in registration', response)
        }
      }
    },
    created(){
      if(this.current_user.id > 0){
        this.user = this.current_user;
      }

      if(this.user.id > 0) {
        this.openConnection(this.user);
        this.getChannels(this.user.id, null, null, this.getChannelsCallback);
      }

      var self = this;
      eventBus.$on('pushMsgTapped', (payload) => {
        if(this.all_channels.length > 0){
            this.all_channels.forEach(element => {
              if(element.id == payload.channel_id){
                    this.setActiveChannel(element);
              }
            });
          }
      });

      eventBus.$on('refreshRegistrationToken', (payload) => {
        if(this.user.id > 0){
          this.registerUserDevice(this.user.id, payload._token);
        }
      });

      eventBus.$on('reconnectUser', (payload) => {
        if(self.user.id > 0) {
          self.openConnection(self.user);
        }
      });
      this.external_dashboard_path = process.env.VUE_APP_WEB_BASE_URL + "/dashboard";
      

      socket.on("connect", () => {
        console.log("user socket connected")
        self.socket_connected = true;
      });

      socket.on("user disconnected", (id) => {
        console.log("user socket disconnected", id);
        setTimeout(function(){
          if(!socket.connected){
            self.socket_connected = false;
          }
        }, 500);
      });

      socket.on("channel message", (payload, from) => {
        if(self[`${payload.content.channel.type}_channels`].length > 0){
            var found = false;
            self[`${payload.content.channel.type}_channels`].forEach(item => {
              if(item.id == payload.content.channel.id){
                found = true;
                if(!item.messages){
                  item.messages = [];
                }
                item.messages.push(payload.content.message);
                let filtered = self.messages.filter((v,i,a)=>a.findIndex(t=>(t.id===v.id))===i);
                item.messages = filtered;
                if(self.user.id != payload.content.message.user_id){
                  item.UserChannel.unread = item.UserChannel.unread + 1 * 1;
                }
              } 
              if(!found){
                self.getChannels(self.user.id, null, null, self.getChannelsCallback);
              }
            });
          } else {
            self.getChannels(self.user.id, null, null, self.getChannelsCallback);
          }
          if(self.selectedChannel.id == payload.content.channel.id){
            if(payload.content.message.user_id != self.user.id){
              if(self.busy == 0){
                self.messages.push(payload.content.message);
                let filtered = self.messages.filter((v,i,a)=>a.findIndex(t=>(t.id===v.id))===i);
                self.messages = filtered;
                self.messages.sort(function (a, b) {
                  if (Date(a.created_at) > Date(b.created_at)) return 1;
                  if (Date(a.created_at) < Date(b.created_at)) return -1;
                  return 0;
                });
                
              } else {
                self.msg_queue.push(payload.content.message);
              }
            }
            if(self.go_down){
              self.show_refresh_button = true;
             } else {
              self.moveScrollToBottom();
             }
          }
          if(payload.content.message.user_id != self.user.id){
            if(Storage.get('is_app_in_background') == 0){
              self.playNotificationSound();
            }
          }
      });

      /* socket.on("subscribed to channel", (payload, from) => {
        if(self[`${payload.content.channel.type}_channels`].length > 0){
          var found = false;
          self[`${payload.content.channel.type}_channels`].forEach(item => {
              if(item.id == payload.content.channel.id){
                found = true;
              }  
            });
            if(!found){
              self[`${payload.content.channel.type}_channels`].push(payload.content.channel);
            }
        } else {
          self[`${payload.content.channel.type}_channels`].push(payload.content.channel);
        }
      }); */
    },
  destroyed(){
    socket.off("connect");
    socket.off("user disconnected");
    socket.off("channel message");
  },
  mounted(){
    var self = this;

    if(this.user.id > 0) {
      if(typeof cordova != "undefined"){
        if (device.platform === "iOS") {
          this.app_version = process.env.VUE_APP_IOS_VERSION;
        } else if(device.platform === "Android"){
          this.app_version = process.env.VUE_APP_ANDROID_VERSION;
        }
      } else {
        this.app_version = process.env.VUE_APP_WEB_VERSION;
      }
    }
    
    this.$nextTick(() => {
      $(".chat-sidebar").addClass("show");
      if(this.getScriptTag('app-assets/js/core/app-menu.js').value){
        this.getScriptTag('app-assets/js/core/app-menu.js').element.remove();
      }
  
      const s1 = document.createElement('script');
      s1.type = 'text/javascript';
      s1. src = 'app-assets/js/core/app-menu.js';
      document.body.appendChild(s1);

      if(this.getScriptTag('app-assets/js/core/app.js').value){
        this.getScriptTag('app-assets/js/core/app.js').element.remove();
      }

      const s2 = document.createElement('script');
      s2.type = 'text/javascript';
      s2. src = 'app-assets/js/core/app.js';
      document.body.appendChild(s2);


      setTimeout(() => {
        if(this.getScriptTag('app-assets/js/scripts/pages/app-chat.js').value){
          this.getScriptTag('app-assets/js/scripts/pages/app-chat.js').element.remove();
        }
        const s3 = document.createElement('script');
        s3.type = 'text/javascript';
        s3. src = 'app-assets/js/scripts/pages/app-chat.js';
        document.body.appendChild(s3);
        //$.app.menu.hide();

        var chatContainer = $(".chat-container");
        if (chatContainer.length > 0) {
          this._container = new PerfectScrollbar(".chat-container");
        }

      }, 500);

      setTimeout(() => {
        $.app.menu.hide();
      }, 850);

      $('#add-contact').on('hidden.bs.modal', function (e) {
        self.new_contact = '';
      });

      $('#add-channel').on('hidden.bs.modal', function (e) {
        self.new_channel = '';
      });

      $('#chat-container').on('scroll', function(ev) {
        if(self.busy == 0 && $(this).scrollTop() < 20 && self.pagination.more) {
            self.getChannelMessgesCall();
        }

        if ($(this).prop('scrollHeight') > $(this).height()) {
          self.go_down = true;
        }

        if($(this).scrollTop() + $(this).innerHeight() >= $(this)[0].scrollHeight){
          self.go_down = false;
          self.show_refresh_button = false;
        }
      });
    });
  }
}
</script>

<style>
.menu-container {
  position: relative;
}
.version-div {
  width: 100%;
  text-align: center;;
  position: absolute;
  bottom: 10px;
  padding: 8px;
}
.chat-application .chat-sidebar .chat-sidebar-list-wrapper li {
    padding: 0.5rem 1.5rem;
}
.files-qty-label{
  font-size: 0.7rem;
  text-align: center;
  width: 10%;
  cursor: pointer;
}
.link-template-own{
  color: #fff;
}
.link-template-contact{
  color: gray;
}
</style>

<style scoped>
.text-content::before {
  content: "Tap";
}

.truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.date-text-right {
  text-align: right;
  width: 100%;
  font-size: 0.8rem;
}
.date-text-left {
  text-align: left;
  width: 100%;
  font-size: 0.8rem;
}

.files-count{
color: #484f56;
font-size: 0.8rem;
text-overflow: ellipsis;
overflow: hidden;
white-space: nowrap;
}

.hide-msg {
  background: transparent !important;
  box-shadow: none !important;
  margin-bottom: 0 !important;
}

.image-upload > input
{
    display: none;
}

.image-upload img
{
    cursor: pointer;
}
.dropdown .dropdown-menu .dropdown-item {
    padding: 0px 40px 0px 20px;
}
.chat-message {
    max-width: 90%;
}
@media (min-width: 576px) {
    .chat-message {
        max-width: 90%;
    }
    .files-qty-label{
      width: 10%;
    }

    .text-content::before {
      content: "Tap";
    }
}
@media (min-width: 768px) {
    .chat-message {
        max-width: 70%;
    }
    .files-qty-label{
      width: 8%;
    }

    .text-content::before {
      content: "Tap";
    }
}
@media (min-width: 992px) {
    .chat-message {
        max-width: 50%;
    }
    .files-qty-label{
      width: 6%;
    }

    .text-content::before {
      content: "Click";
    }
}
@media (min-width: 1200px) {
    .chat-message {
        max-width: 30%;
    }
    .files-qty-label{
      width: 4%;
    }
    .text-content::before {
      content: "Click";
    }
}


.scroll-down-btn, .btn-outline-secondary:hover {
    height: 40px;
    width: 40px;
    position: fixed;
    bottom: 14%;
    right: 20px;
    background-color: #fff !important;
    color: gray !important;
    box-shadow: 0 6px 10px rgb(0 0 0 / 15%);
    z-index: 2;
}


/* 
You want a simple and fancy tooltip?
Just copy all [data-tooltip] blocks:
*/
[data-tooltip] {
  position: relative;
  z-index: 1;
}

/* Positioning and visibility settings of the tooltip */
[data-tooltip]:before,
[data-tooltip]:after {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  left: 50%;
  bottom: calc(100% + 5px); /* 5px is the size of the arrow */
  pointer-events: none;
  transition: 0.2s;
  will-change: transform;
}

/* The actual tooltip with a dynamic width */
[data-tooltip]:before {
  content: attr(data-tooltip);
  padding: 10px 18px;
  min-width: 50px;
  max-width: 300px;
  width: max-content;
  width: -moz-max-content;
  border-radius: 6px;
  font-size: 14px;
  background-color: rgba(59, 72, 80, 0.9);
  background-image: linear-gradient(30deg,
    rgba(59, 72, 80, 0.44),
    rgba(59, 68, 75, 0.44),
    rgba(60, 82, 88, 0.44));
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
  color: #fff;
  text-align: center;
  white-space: pre-wrap;
  transform: translate(-50%, -5px) scale(0.5);
}

/* Tooltip arrow */
[data-tooltip]:after {
  content: '';
  border-style: solid;
  border-width: 5px 5px 0px 5px;
  border-color: rgba(55, 64, 70, 0.9) transparent transparent transparent;
  transition-duration: 0s; 
  transform-origin: top;   
  transform: translateX(-50%) scaleY(0);
}

/* Tooltip becomes visible at hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}
/* Scales from 0.5 to 1 -> grow effect */
[data-tooltip]:hover:before {
  transition-delay: 0.3s;
  transform: translate(-50%, -5px) scale(1);
}
/* 
  Arrow slide down effect only on mouseenter (NOT on mouseleave)
*/
[data-tooltip]:hover:after {
  transition-delay: 0.5s; /* Starting after the grow effect */
  transition-duration: 0.2s;
  transform: translateX(-50%) scaleY(1);
}

[data-tooltip-location="left"]:before,
[data-tooltip-location="left"]:after {
  left: auto;
  right: calc(100% + 5px);
  bottom: 50%;
}


[data-tooltip-location="left"]:before {
  transform: translate(-5px, 50%) scale(0.5);
}
[data-tooltip-location="left"]:hover:before {
  transform: translate(-5px, 50%) scale(1);
}

/* Arrow */
[data-tooltip-location="left"]:after {
  border-width: 5px 0px 5px 5px;
  border-color: transparent transparent transparent rgba(55, 64, 70, 0.9);
  transform-origin: left;
  transform: translateY(50%) scaleX(0);
}
[data-tooltip-location="left"]:hover:after {
  transform: translateY(50%) scaleX(1);
}

/* RIGHT */
[data-tooltip-location="right"]:before,
[data-tooltip-location="right"]:after {
  left: calc(100% + 5px);
  bottom: 50%;
}

[data-tooltip-location="right"]:before {
  transform: translate(5px, 50%) scale(0.5);
}
[data-tooltip-location="right"]:hover:before {
  transform: translate(5px, 50%) scale(1);
}

[data-tooltip-location="right"]:after {
  border-width: 5px 5px 5px 0px;
  border-color: transparent rgba(55, 64, 70, 0.9) transparent transparent;
  transform-origin: right;
  transform: translateY(50%) scaleX(0);
}
[data-tooltip-location="right"]:hover:after {
  transform: translateY(50%) scaleX(1);
}

/* BOTTOM */
[data-tooltip-location="bottom"]:before,
[data-tooltip-location="bottom"]:after {
  top: calc(100% + 5px);
  bottom: auto;
}

[data-tooltip-location="bottom"]:before {
  transform: translate(-50%, 5px) scale(0.5);
}
[data-tooltip-location="bottom"]:hover:before {
  transform: translate(-50%, 5px) scale(1);
}

[data-tooltip-location="bottom"]:after {
  border-width: 0px 5px 5px 5px;
  border-color: transparent transparent rgba(55, 64, 70, 0.9) transparent;
  transform-origin: bottom;
}

@keyframes moveFocus { 
  0%   { background-position: 0% 100% }
  100% { background-position: 100% 0% }
}

main {
  padding: 0 4%;
  display: flex;
  flex-direction: row;
  margin: auto 0;
}

button {
  margin: 0;
  padding: 0.7rem 1.4rem;

  cursor: pointer;
  text-align: center;
  border: none;
  border-radius: 4px;
  outline: inherit;
  text-decoration: none;
  font-family: Roboto, sans-serif;
  font-size: 0.7em;
  background-color: rgba(174, 184, 192, 0.55);
  color: white;

  -webkit-appearance: none;
  -moz-appearance: none;
  transition: background 350ms ease-in-out, 
              transform 150ms ease;
}
button:hover {
  background-color: #484f56;
}
button:active {
  transform: scale(0.98);
}
button:focus {
  box-shadow: 0 0 2px 2px #298bcf;
}
button::-moz-focus-inner {
  border: 0;
}

.info-wrapper {
  flex-grow: 8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: justify;
  padding-left: 6%;
  border-left: 3px solid #35ea95;
}

.info-wrapper p {
  color: rgba(255, 255, 255, 0.69);
}
.info-wrapper p {
  max-width: 600px;
  text-align: justify;
}

.info-wrapper .title-question {
  display: block;
  color: #fff;
  font-size: 1.36em;
  font-weight: 500;
  padding-bottom: 24px;
}


@media (max-height: 450px) {
  main {
    margin: 2rem 0;
  }
}

@media (max-width: 800px) {
  html {
    font-size: 0.9em;
  }
}

/* Thumbnail settings */
@media (max-width: 750px) {
  main {
    font-size: 1.1em;
    padding: 6%;
  }
  .info-wrapper p:before,
  .info-wrapper p:after {
    display: none;
  }
  
  [data-tooltip]:after {
    bottom: calc(100% + 3px);
  }
  [data-tooltip]:after {
    border-width: 7px 7px 0px 7px;
  }
}

.float-btn-refresh{
    padding-bottom: 5px;
    padding-top: 5px;
    background: #fff;
    color: #797777;
    border: 1px solid #d4d4d4;
    width: 165px;
    border-radius: 100px;
    text-align: center;
    overflow: hidden;
    transition: all .1s ease;
    cursor: pointer;
    font-size: 0.7em;
    font-weight: bold;
    text-decoration: none;
}

.float-btn-refresh:hover {
    background-color: #fff;
}

.badge{
  border: 1px solid #fff;
  background-color: #ff4b64;
  color: #fff;
  border-radius: 50%;
  padding-top: 7px;
  padding-left: 2px;
  font-weight: 600;
  width: 25px;
  height: 25px;
  text-align: center;
  font-size: 9px;
  margin-left: 10%;
}

.noClick {
   pointer-events: none;
}

.dropdown-item, .menu-title{
    font-weight: 500 !important;
}
</style>

<style scoped>
.brand-logo {
    width: 200px;
    height: auto;
    margin-left: -5%;
}
.header-navbar .navbar-header .navbar-brand {
    padding: 15px 0px;
}


@media (min-width: 576px) { 
    .brand-logo {
    width: 230px;
    height: auto;
    margin-left: 0;
 }
 .header-navbar .navbar-header .navbar-brand {
    padding: 15px 0px;
}
}

@media (min-width: 768px) { 
    .brand-logo {
    width: 250px;
    height: auto;
    margin-left: 0;
 }
 .header-navbar .navbar-header .navbar-brand {
    padding: 10px 0px;
}
}

@media (min-width: 992px) { 
    .brand-logo {
    width: 260px;
    height: auto;
    margin-left: 0;
 }
 .header-navbar .navbar-header .navbar-brand {
    padding: 10px 0px;
}
}

@media (min-width: 1200px) { 
    .brand-logo {
    width: 270px;
    height: auto;
 }
 .header-navbar .navbar-header .navbar-brand {
    padding: 10px 0px;
 }
}

.center-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.chaser {
  display: inline-block;
  position: relative;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  box-shadow:  12.8557520113px 15.3208893599px 0 gray, 19.6961549775px 3.4729645503px 0 gray, 17.320508433px -9.99999891px 0 gray, 6.8404037618px -18.7938515169px 0 gray, -6.8404017451px -18.7938412951px 0 gray, -17.3205072084px -9.9997757649px 0 gray, -19.6961501101px 3.4729634947px 0 gray, -12.8556419681px 15.320888671px 0 gray, 0px 20px 0 gray, 0 0 0 transparent;;
  -webkit-animation: rot 1.6s cubic-bezier(0.6, 0.3, 0.3, 0.6) infinite;;
          animation: rot 1.6s cubic-bezier(0.6, 0.3, 0.3, 0.6) infinite;
}
@-webkit-keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
