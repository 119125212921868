import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import _storage from '../utils/storage'
import Chat from '../components/Chat'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Chat',
    component: Chat,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const auth_token = _storage.get('token');
  const user = _storage.get('user');
  window.isAuthenticated = false;
  window.user = null;
  if (auth_token && user) {
    window.isAuthenticated = true
    window.user = user
  }
  if (to.name !== 'Login' && !window.isAuthenticated) {
    next({ name: 'Login' })
  }
  else if (to.name == 'Login') {
    if (window.isAuthenticated) {
      next({ name: 'Chat' })
    }
    else {
      next()
    }
  }
  else {
    next()
  }
})

export default router
