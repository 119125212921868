<template>
<div class="row" :class="{'justify-content-end' :  messageUserId == user.id, 'with-msg-adj-left' : (messageUserId != user.id && hasText),
  'with-msg-adj-right' :  (messageUserId == user.id && hasText)}" style="padding-right: 5%">
  <div v-for="(file, index) in files" :key="index" class="col-10 col-sm-9 col-md-4 col-xl-3">
    <div class="card" style="border-radius: 10px; margin-top: 1%;">
      <div class="card-content">
        <span class="dropdown mt-1" style="float: right;">
          <i class="feather icon-more-vertical font-medium-4 ml-25 cursor-pointer dropdown-toggle nav-hide-arrow cursor-pointer" style="color: #d0d0d0" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="menu">
          </i>
          <span class="dropdown-menu dropdown-menu-right">
              <a class="dropdown-item" :href="getDownloadFileUrl(file.uid)"><i class="feather icon-download mr-25"></i>Download</a>
              <a @click="view(file.uid)" class="dropdown-item" href="#"><i class="feather icon-eye mr-25"></i>View</a>
              <a v-if="user.id == messageUserId" @click="deleteFile(file.uid)" class="dropdown-item" href="#"><i class="feather icon-trash-2 mr-25"></i>Delete</a>
          </span>
        </span>
        <div class="row" style="width: 100%;">
            <div class="col-12">
              <div v-if="fileIsImage(file.type)" @click="view(file.uid)" class="thumbnail float-left mt-1 mb-1 ml-1 img-fluid" :style="getUrlBackground(file)"></div>
              <div v-else-if="fileIsPDF(file.type)" @click="view(file.uid)" class="thumbnail float-left mt-1 mb-1 ml-1 img-fluid" :style="getStaticBackground('assets/images/pdf-image-icon.svg')"></div>
              <div v-else-if="fileIsDoc(file.type)" @click="view(file.uid)" class="thumbnail float-left mt-1 mb-1 ml-1 img-fluid" :style="getStaticBackground('assets/images/word-doc.png')"></div>
              <video v-else-if="fileIsVideo(file.type)" :id="'video' + file.id" controls class="content">
                <source :src="getVideoFilePath(file.filename)">
                <span style="font-size: 10px;">Your browser does not support the video tags :(</span>
              </video>
              <div v-else class="thumbnail float-left mt-1 mb-1 ml-1 img-fluid" @click="view(file.uid)" :style="getStaticBackground('assets/images/generic-file.png')"></div>
            </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="chat-time" :class="{'date-text-left' : messageUserId != user.id, 'date-text-right' : messageUserId == user.id,
  'time-adjust-right' :  messageUserId == user.id, 'time-adjust-left' :  messageUserId != user.id}">
    <div>{{moment(createdAt).format("ddd, MMM D")}} <span style="font-size: 0.7rem;">{{moment(createdAt).format("h:mm a")}}</span></div>
  </div> -->
</div>
</template>

<script>
import filesService from "@/services/files.js";
import moment from 'moment';

export default {
  name: 'Files',
  mixins: [filesService],
  props: {
    files: Array,
    messageUserId: Number,
    messageId: Number,
    createdAt: String,
    user: Object,
    hasText: Boolean
  },
  data(){
    return {
      moment: moment,
      basePath: ''
    }
  },
  methods: {
    fileIsImage(type){
      const validImageMimes = ['image/jpeg','image/jpg', 'image/png']
      if(validImageMimes.includes(type)){
        return true;
      }
      return false;
    },
    fileIsPDF(type){
      const validPDFMimes = ['application/pdf']
      if(validPDFMimes.includes(type)){
        return true;
      }
      return false;
    },
    fileIsDoc(type){
      const validDocMimes = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
      if(validDocMimes.includes(type)){
        return true;
      }
      return false;
    },
    fileIsVideo(type){
      const validVideoMimes = ['video/quicktime', 'video/mp4', 'video/mpeg', 'video/x-ms-wmv', 'video/webm', 'video/ogg', 'video/3gpp', 'video/avi']
      if(validVideoMimes.includes(type)){
        return true;
      }
      return false;
    },
    view(uid){
      window.open(process.env.VUE_APP_API_BASE_URL + '/files/' + uid)
    },
    getVideoFilePath(filename){
      return process.env.VUE_APP_SERVER + '/' + filename;
    },
    getDownloadFileUrl(uid){
      return process.env.VUE_APP_API_BASE_URL + '/files/' + uid + '/download'
    },
    deleteFile(uid){
      this.destroy(uid, this.destroyCallback)
    },
    destroyCallback(response){
      if(response.status == 1){
        this.$emit('fileRemoved', {id: response.data.id, message_id: this.messageId})
      } else {
        Swal.fire({
            title: response.errors[0],
            confirmButtonClass: "btn btn-primary",
            buttonsStyling: false
        });
      }
    },
    getUrlBackground(file){
      this.$emit("renderCompleted")
      return {
        'backgroundImage' : `url(${this.basePath + file.uid})`
      }
    },
    getStaticBackground(path){
      if(typeof cordova != "undefined"){
        return {
          'backgroundImage' : `url(${path})`
        }
      } else {
        return {
          'backgroundImage' : `url(/${path})`
        }
      }
     }
  },
  created(){
    this.basePath = process.env.VUE_APP_API_BASE_URL + '/files/'
  },
  mounted(){
    
  }
}
</script>

<style scoped>
.chat-time{
  margin-right: 5% !important;
}
.time-adjust-left {
  margin-left: 1%;
}
.time-adjust-right {
  margin-right: 1%;
}
.date-text-right {
  text-align: right;
  width: 100%;
  font-size: 0.8rem;
}
.date-text-left {
  text-align: left;
  width: 100%;
  font-size: 0.8rem;
}

.with-msg-adj-left {
  margin-left: 6%;
}
.with-msg-adj-right {
  margin-right: 6%;
}
.thumbnail {
    background-color: #fff;
    width: 100%;
    height: 250px;
    display: inline-block; /* makes it fit in like an <img> */
    background-size: contain; /* or contain */
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
}
.content {
  width: inherit;
  height: auto;
  margin-left: 10px;
  margin-top: 10px;
}
</style>
