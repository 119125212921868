<template>
   <!-- app chat user profile left sidebar start -->
   <div class="chat-user-profile">
    <header class="chat-user-profile-header text-center border-bottom">
        <span class="chat-profile-close">
            <i class="feather icon-x"></i>
        </span>
        <div class="my-2">
            <img :src="user.avatar" class="round mb-1" alt="user_avatar" height="100" width="100">
            <h5 class="mb-0">{{user.firstname}} {{user.lastname}}</h5>
            <!-- <span>Designer</span> -->
        </div>
    </header>
    <div class="chat-user-profile-content">
        <div class="chat-user-profile-scroll">
            <!-- <h6 class="text-uppercase mb-1">ABOUT</h6>
            <p class="mb-2">It is a long established fact that a reader will be distracted by the readable content .</p> -->
            <h6>CONTACT INFORAMTION</h6>
            <ul class="list-unstyled mb-2">
                <li class="mb-25">{{user.email}}</li>
                <li v-if="user.phone">{{user.phone}}</li>
            </ul>
            <!-- <h6 class="text-uppercase mb-1">CHANNELS</h6>
            <ul class="list-unstyled mb-2">
                <li><a href="javascript:void(0);"># Devlopers</a></li>
                <li><a href="javascript:void(0);"># Designers</a></li>
            </ul>
            <h6 class="text-uppercase mb-1">SETTINGS</h6>
            <ul class="list-unstyled">
                <li class="mb-50 "><a href="javascript:void(0);" class="d-flex align-items-center"><i class="feather icon-tag mr-50"></i>
                        Add
                        Tag</a></li>
                <li class="mb-50 "><a href="javascript:void(0);" class="d-flex align-items-center"><i class="feather icon-star mr-50"></i>
                        Important Contact</a>
                </li>
                <li class="mb-50 "><a href="javascript:void(0);" class="d-flex align-items-center"><i class="feather icon-image mr-50"></i>
                        Shared
                        Documents</a></li>
                <li class="mb-50 "><a href="javascript:void(0);" class="d-flex align-items-center"><i class="feather icon-trash-2 mr-50"></i>
                        Deleted
                        Documents</a></li>
                <li><a href="javascript:void(0);" class="d-flex align-items-center"><i class="feather icon-x-circle mr-50"></i>
                        Blocked
                        Contact</a></li>
            </ul> -->
        </div>
    </div>
</div>
<!-- app chat user profile left sidebar ends -->
</template>

<script>
// @ is an alias to /src
export default {
  name: 'Profile',
  props: {
    user: Object
  }
}
</script>
