<template>
    <!-- BEGIN: Footer-->
    <footer class="footer fixed-bottom footer-light navbar-border navbar-shadow">
        <p class="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2"><span class="d-block d-md-inline-block">{{year}} <a class="text-bold-800 grey darken-2" href="https://speakeasyforsports.com" target="_blank">{{app_name}} </a></span><span class="float-md-right d-none d-lg-block"><!-- Crafted by <a href="https://www.properos.com" target="_blank">PROPEROS LLC</a> --></span></p>
    </footer>
    <!-- END: Footer-->
</template>

<script>
export default {
  name: 'Footer',
  props: {
    
  },
  data(){
    return {
      year: '',
      app_name: 'sdfsdfsd'
    }
  },
  created(){
    this.year = new Date().getFullYear()
    this.app_name = process.env.VUE_APP_NAME
  }
}
</script>

<style scoped>

</style>
