<template>
<div>
  <nav class="header-navbar navbar-flipped navbar-expand-md navbar navbar-with-menu fixed-top navbar-dark bg-primary navbar-shadow navbar-brand-center">
    <div class="navbar-wrapper">
        <div class="navbar-header" style="padding-right: 7px;padding-left: 7px;">
            <ul class="nav navbar-nav flex-row" style="text-align: center;">
                <li class="nav-item mobile-menu d-md-none mr-auto"><a class="nav-link nav-menu-main menu-toggle hidden-xs" href="#" style="margin-top: 3px;"><i class="feather icon-menu font-large-1"></i></a></li>
                <li class="nav-item"><a class="navbar-brand" href="/"><img class="brand-logo" src="assets/images/custom-logo.png" style="text-align: center;"></a></li>
                <li class="nav-item d-none"><a class="nav-link open-navbar-container" data-toggle="collapse" data-target="#navbar-mobile"><i class="fa fa-ellipsis-v"></i></a></li>
            </ul>
        </div>
        <div class="navbar-container content" style="width: 100%;">
            <div class="collapse navbar-collapse" id="navbar-mobile">
                <ul class="nav navbar-nav mr-auto float-left">
                    <li class="nav-item d-none d-md-block"><a class="nav-link nav-menu-main menu-toggle hidden-xs d-md-none" href="#" style="margin-top: 3px;"><i class="feather icon-menu"></i></a></li>
                </ul>
                <ul class="nav navbar-nav float-right">
                    <li class="dropdown dropdown-user nav-item">
                        <a class="dropdown-toggle nav-link dropdown-user-link" href="#" data-toggle="dropdown">
                            <!-- <div class="avatar avatar-online">
                                <img :src="user.avatar" alt="avatar">
                                <i></i>
                            </div> -->
                            <span class="user-name">{{user.firstname}} {{user.lastname}}</span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" :href="external_dashboard_path" target="_blank"><i class="feather icon-layout"></i> My Dashboard</a>
                            <div class="dropdown-divider"></div>
                            <a @click="logout" class="dropdown-item" style="cursor: pointer"><i class="feather icon-power"></i> Logout</a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>

<div class="main-menu menu-fixed menu-light menu-accordion menu-shadow menu-border right">
    <div class="main-menu-content">
        <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
            <li>
                <a>
                    <div class="avatar avatar-online">
                        <img :src="user.avatar" alt="avatar">
                    </div>
                    <span class="user-name ml-1" style="font-weight: 700; font-size: 0.8rem;">{{user.firstname}} {{user.lastname}}</span>
                </a>
                <div class="dropdown-divider"></div>
                <a @click="closeMenu" :href="external_dashboard_path" target="_blank"><i class="feather icon-layout"></i><span class="menu-title" data-i18n="Dashboard">My Dashboard</span>
                    <!-- <span class="badge badge badge-primary badge-pill float-right mr-2">3</span> -->
                </a>
                <a @click="logout" style="cursor: pointer;"><i class="feather icon-power"></i><span class="menu-title" data-i18n="Dashboard">Logout</span>
                    <!-- <span class="badge badge badge-primary badge-pill float-right mr-2">3</span> -->
                </a>
           </li>
        </ul>
    </div>
</div>

</div>
</template>
<script>
  import Storage from '@/utils/storage.js'
  
  export default {
    name: 'Navbar',
    props: {
        user: Object,
    },
    data(){
        return {
            external_dashboard_path: ""
        }
    },
    methods : {
        logout(){
            Storage.remove("token");
            Storage.remove("user");
            Storage.remove("reloaded");
            this.$router.push("/login");
            window.location.reload();
        },
        closeMenu(){
            $.app.menu.hide()
            $(".chat-sidebar-toggle").click();
        },
        getScriptTag(src) {
            return {
                value : Boolean(document.querySelector('script[src="' + src + '"]')),
                element: document.querySelector('script[src="' + src + '"]')
            }
        }
    },
    created(){
        this.external_dashboard_path = process.env.VUE_APP_WEB_BASE_URL + "/dashboard"
    },
    mounted(){
        this.$nextTick(() => {
            
        });

    }
}
</script>
<style>
.dropdown-item, .menu-title{
    font-weight: 500 !important;
}
</style>

<style scoped>
.brand-logo {
    width: 200px;
    height: auto;
    margin-left: -5%;
}
.header-navbar .navbar-header .navbar-brand {
    padding: 15px 0px;
}


@media (min-width: 576px) { 
    .brand-logo {
    width: 230px;
    height: auto;
    margin-left: 0;
 }
 .header-navbar .navbar-header .navbar-brand {
    padding: 15px 0px;
}
}

@media (min-width: 768px) { 
    .brand-logo {
    width: 250px;
    height: auto;
    margin-left: 0;
 }
 .header-navbar .navbar-header .navbar-brand {
    padding: 10px 0px;
}
}

@media (min-width: 992px) { 
    .brand-logo {
    width: 260px;
    height: auto;
    margin-left: 0;
 }
 .header-navbar .navbar-header .navbar-brand {
    padding: 10px 0px;
}
}

@media (min-width: 1200px) { 
    .brand-logo {
    width: 270px;
    height: auto;
 }
 .header-navbar .navbar-header .navbar-brand {
    padding: 10px 0px;
 }
}
</style>
