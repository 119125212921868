<template>
  <router-view :current_user="$store.state.user" :key="$route.fullPath"/>
</template>

<script>
  // @ is an alias to /src

  import socket from "./socket";
  import Storage from '@/utils/storage.js'
  
  export default {
    name: 'App',
    data() {
      return {
        transitionName: 'slide-left',
        usernameAlreadySelected: false
      }
    },
    created(){
      if(window.user){
        this.$store.commit("setUser", JSON.parse(window.user));
      }
      socket.on("connect_error", (err) => {
          console.log(err.message)
      });
    },
    destroyed(){
      socket.off("connect_error");
    }
  }
  </script>

<style>

</style>


