export default {
    methods: {
        getChannels(id, created_channel_id, created_channel_type, callBackHandler) {
            axios({
                method: 'get',
                url: `/internal/users/${id}/channels`,
            }).then(function (response) {
                if (created_channel_id != null && created_channel_type != null) {
                    callBackHandler(response.data, created_channel_id, created_channel_type);
                } else {
                    callBackHandler(response.data, null, null);
                }
            }.bind(this)).catch(function (error) {
                if (error.response) {
                    if (created_channel_id != null && created_channel_type != null) {
                        callBackHandler(error.response, created_channel_id, created_channel_type);
                    } else {
                        callBackHandler(error.response, null, null);
                    }

                }
            })
        },
        getChannelMessges(id, callBackHandler) {
            axios({
                method: 'get',
                url: `/internal/channels/${id}/messages`,
            }).then(function (response) {
                callBackHandler(response.data);
            }.bind(this)).catch(function (error) {
                if (error.response) {
                    callBackHandler(error.response);
                }
            })
        },
        getChannelUsers(id, callBackHandler) {
            axios({
                method: 'get',
                url: `/internal/channels/${id}/participants`,
            }).then(function (response) {
                callBackHandler(response.data);
            }.bind(this)).catch(function (error) {
                if (error.response) {
                    callBackHandler(error.response);
                }
            })
        },
        createPrivateChannel(data, contact_id, callBackHandler) {
            axios({
                method: 'post',
                url: '/internal/channels',
                data: data
            }).then(function (response) {
                callBackHandler(response.data, contact_id);
            }.bind(this)).catch(function (error) {
                if (error.response) {
                    callBackHandler(error.response, contact_id);
                }
            })
        },
        resetUnreadMessages(id, user_id, callBackHandler) {
            axios({
                method: 'get',
                url: `/internal/channels/${id}/users/${user_id}`
            }).then(function (response) {
                callBackHandler(response.data);
            }.bind(this)).catch(function (error) {
                if (error.response) {
                    callBackHandler(error.response);
                }
            })
        }
    }
}