<template>
  <div class="modal fade text-left" id="files-preview" tabindex="-1" role="dialog" aria-labelledby="myModalLabel1" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="myModalLabel1" style="font-size: 1rem;">Files upload</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div v-if="files.length > 0" class="modal-body row">
              <div v-for="(file, index) in files" :key="index" class="col-xs-8 offset-xs-2 col-sm-4">
                <div class="card" style="border-radius: 10px; margin-top: 1%;">
                  <div class="card-content">
                    <span class="dropdown mt-1" style="float: right;">
                      <i class="feather icon-more-vertical font-medium-4 ml-25 cursor-pointer dropdown-toggle nav-hide-arrow cursor-pointer" style="color: #d0d0d0" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="menu">
                      </i>
                      <span class="dropdown-menu dropdown-menu-right">
                          <a @click="deleteFile(index)" class="dropdown-item p-0 pl-1" href="#"><i class="feather icon-trash-2 mr-25"></i>Delete</a>
                      </span>
                    </span>
                    <div class="row">
                        <div class="col-12">
                          <div v-if="fileIsImage(file.type)" class="thumbnail float-left mt-1 mb-1 ml-1 img-fluid" :style="getUrlBackground(file)"></div>
                          <div v-else-if="fileIsPDF(file.type)" class="thumbnail float-left mt-1 mb-1 ml-1 img-fluid" style="background-image : url(assets/images/pdf-image-icon.svg)"></div>
                          <div v-else-if="fileIsDoc(file.type)" class="thumbnail float-left mt-1 mb-1 ml-1 img-fluid" style="background-image : url(assets/images/word-doc.png)"></div>
                          <div v-else-if="fileIsVideo(file.type)" class="thumbnail float-left mt-1 mb-1 ml-1 img-fluid" style="background-image : url(assets/images/movie.jpg)"></div>
                          <div v-else class="thumbnail float-left mt-1 mb-1 ml-1 img-fluid" style="background-image : url(assets/images/generic-file.png)"></div>
                        </div>
                    </div>
                  </div>
                  <div class="card-footer" style="padding: 0.5rem 0.5rem">
                    <div style="font-size: 0.8rem;">
                      {{file.name}}
                    </div>
                  </div>
                </div>
              </div>

                
            </div>
            <div class="modal-footer">
                <button type="button" class="btn grey btn-outline-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>

export default {
  name: 'FilesPreview',
  props: ['files'],
  data(){
    return {
     
    }
  },
  methods: {
    fileIsImage(type){
      const validImageMimes = ['image/jpeg','image/jpg', 'image/png', 'image/svg', 'image/gif', 'image/webp', 'image/bmp', 'image/svg+xml']
      if(validImageMimes.includes(type)){
        return true;
      }
      return false;
    },
    fileIsPDF(type){
      const validPDFMimes = ['application/pdf']
      if(validPDFMimes.includes(type)){
        return true;
      }
      return false;
    },
    fileIsDoc(type){
      const validDocMimes = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
      if(validDocMimes.includes(type)){
        return true;
      }
      return false;
    },
    fileIsVideo(type){
      const validVideoMimes = ['video/quicktime', 'video/mp4', 'video/mpeg', 'video/x-ms-wmv', 'video/webm', 'video/ogg', 'video/3gpp', 'video/avi']
      if(validVideoMimes.includes(type)){
        return true;
      }
      return false;
    },
    getUrlBackground(file){
      var url = URL.createObjectURL(file)
      return {
        'backgroundImage' : `url(${url})`
      }
    },
    deleteFile(index){
      if(this.files.length > 0){
        this.$emit('filePreviewRemoved', index)
      }
    }
  },
  created(){
    
  },
  mounted(){
    
  }
}
</script>

<style scoped>
#files-preview{
  padding-left: 0;
}
.modal-content {
    max-height: 98vh; overflow-y: auto;
    overflow-x: hidden;
}
.modal-content{
  border-radius: 0;
}

.time-adjust-left {
  margin-left: 1%;
}
.time-adjust-right {
  margin-right: 1%;
}
.date-text-right {
  text-align: right;
  width: 100%;
  font-size: 0.8rem;
}
.date-text-left {
  text-align: left;
  width: 100%;
  font-size: 0.8rem;
}

.with-msg-adj-left {
  margin-left: 6%;
}
.with-msg-adj-right {
  margin-right: 6%;
}
.thumbnail {
    background-color: #fff;
    width: 100%;
    height: 200px;
    display: inline-block; /* makes it fit in like an <img> */
    background-size: contain; /* or contain */
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
}
</style>
