import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Storage from './utils/storage'
import { eventBus } from './eventBus';
/* import { register } from 'register-service-worker' */

global.helpers = require('@/misc/helpers')
Vue.config.productionTip = false

var chat = {
  // Application Constructor
  initialize: function () {
    if (typeof cordova != 'undefined') {
      document.addEventListener('deviceready', this.onDeviceReady.bind(this), false);
    } else {
      document.addEventListener("DOMContentLoaded", this.onDeviceReady.bind(this));
    }
  },

  // deviceready Event Handler
  //
  // Bind any cordova events here. Common events are:
  // 'pause', 'resume', etc.
  onDeviceReady: function () {

    window.axios = require('axios');
    window.axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
    window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    window.axios.interceptors.request.use(function (config) {
      const AUTH_TOKEN = Storage.get('token');
      if (AUTH_TOKEN) {
        config.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`
      }
      return config;
    }, function (error) {
      return Promise.reject(error);
    });

    window.axios.interceptors.response.use(function (response) {
      // Do something with response data
      return response;

    }, function (error) {
      if (error.response.status == 401) {
        Storage.remove('token');
        Storage.remove('user');
        _instance.$router.push('/login')
      }
      // Do something with response error
      return Promise.reject(error);
    });

    var _instance = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app');


    if (typeof cordova != "undefined") {
      var messages = 0;
      Storage.set('is_app_in_background', 0);

      document.addEventListener("pause", function pauseCallback() {
        Storage.set('is_app_in_background', 1);
      });

      document.addEventListener("resume", function () {
        messages = 0;
        cordova.plugins.firebase.messaging.setBadge(messages);
        Storage.set('is_app_in_background', 0);
        setTimeout(function () {
          eventBus.$emit("reconnectUser");
          let last_push_channel = Storage.get('last_push_channel');
          if (last_push_channel > 0) {
            eventBus.$emit("pushMsgTapped", { channel_id: last_push_channel });
            Storage.remove('last_push_channel');
          }
        }, 0);
      });

      Storage.set('device_uuid', device.uuid);
      Storage.set('device_platform', device.platform);
      Storage.set('device_model', device.model);
      Storage.set('device_serial', device.serial);
      Storage.set('device_is_virtual', false);
      Storage.set('device_status', 'active');

      cordova.plugins.firebase.messaging.requestPermission().then(function () {
        //console.log("Push messaging is allowed");
      });

      function getToken() {
        cordova.plugins.firebase.messaging.getToken().then(function (token) {
          //Storage.set('registration_token', token);
          return token;
        });
      }

      cordova.plugins.firebase.messaging.onTokenRefresh(function () {
        var token = getToken();
        if (token) {
          eventBus.$emit("refreshRegistrationToken", { "_token": token });
        }
        console.log("Device token refreshed");
      });

      cordova.plugins.firebase.messaging.onMessage(function (payload) {
        Storage.set('last_push_channel', payload.channel_id);
        //console.log("New foreground FCM message: ", payload);
      });

      cordova.plugins.firebase.messaging.onBackgroundMessage(function (payload) {
        if (device.platform === "iOS") {
          messages = messages + 1;
          cordova.plugins.firebase.messaging.setBadge(messages);
        }
        Storage.set('last_push_channel', payload.channel_id);
        //console.log("New background FCM message: ", payload);
      });
    }
  }
};
chat.initialize();