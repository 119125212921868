<template>
  <div class="splash-content">
    <img class="logo-splash" src="assets/images/custom-logo.png"/>
  </div>
</template>
<script>
export default {
  name: "WebSplash",
};
</script>

<style scoped>
.splash-content{
  min-height: 100vh;
  overflow: hidden;
  text-align: center;
}
.logo-splash{
  margin-top: 60%;
  width: 80%;
  height: auto;
}

@media (min-width: 576px) { 
  .logo-splash{
  margin-top: 40%;
  width: 50%;
  height: auto;
}
}

@media (min-width: 768px) { 
  .logo-splash{
  margin-top: 30%;
  width: 40%;
  height: auto;
}
}

@media (min-width: 992px) { 
  .logo-splash{
  margin-top: 30%;
  width: 30%;
  height: auto;
}
}

@media (min-width: 1200px) { 
  .logo-splash{
  margin-top: 20%;
  width: 20%;
  height: auto;
}
}
</style>
